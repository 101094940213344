import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Sermons from "./Content/HomePage/Sermons";
import EasterFriday from "./Content/HomePage/EasterFriday";
import Home from "./Content/HomePage/Home";
import Worship from "./Content/HomePage/Worship";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © Patmos Fellowship "}

      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function App() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container>
      <div className={classes.root}>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Patmos Fellowship
        </Typography>
        <AppBar position="static">
          <Tabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            scrollButtons="auto"
            className={classes.tabs}
          >
            <Tab label="Home" {...a11yProps(0)} />
            <Tab label="Sermons" {...a11yProps(1)} />
            <Tab label="Worship" {...a11yProps(2)} />
            <Tab label="Easter" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Home />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Sermons />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Worship />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <EasterFriday />
        </TabPanel>
      </div>

      <Copyright />
    </Container>
  );
}
