import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  paperCards: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Worship() {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom align="center">
        Worship Songs
      </Typography>
      <div className={classes.root}>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            The Blessing By Kari Jobe & Cody Carnes
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <iframe
              title="Loading...."
              frameBorder="0"
              src="https://www.youtube.com/embed/Zp6aygmvzM4"
            >
              Loading .....
            </iframe>
          </Typography>
        </Paper>
      </div>
    </>
  );
}
